<template>
  <v-container v-if="selected != null">
    <v-row class="my-0" dense>
      <v-col cols="12" sm="12">
        <single-image-uploader
          id="add-uploader"
          :apiUrl="imageApiUrl"
          v-model="selected.logoUrl"
          :disabled="readonly"
        ></single-image-uploader>
      </v-col>
      <v-col cols="12" sm="12">
        <v-text-field-alt
          :rules="[allRules.required, allRules.length(2), allRules.noWhiteSpaces]"
          label="Name"
          id="Name"
          ref="Name"
          placeholder="Facebook"
          v-model="selected.name"
          :readonly="readonly"
        >
        </v-text-field-alt>
        <v-text-field-alt
          :rules="[allRules.required, allRules.isClientAbbr, allRules.noWhiteSpaces]"
          label="Abbreviated Name"
          id="AbbreviatedName"
          placeholder="FB"
          v-model="selected.abbreviatedName"
          :readonly="readonly"
          clearable
        >
        </v-text-field-alt>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "company-basic-information",
  data() {
    return { selected: null };
  },
  props: {
    imageApiUrl: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    this.selected = this.cloneDeep(this.value);
  },
  watch: {
    isActive: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.$refs.Name.focus();
          });
        }
      },
      immediate: true,
    },
    value: {
      handler() {
        if (!this.isEqual(this.selected, this.value)) {
          this.selected = this.value;
        }
      },
      deep: true,
    },
    selected: {
      handler() {
        if (!this.isEqual(this.selected, this.value)) {
          this.$emit("input", this.selected);
        }
      },
      deep: true,
    },
  },
};
</script>

<style></style>
