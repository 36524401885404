export default [
  {
    text: "Actions",
    value: "actions",
    sortable: false,
    order: 0,
    width: "60px",
  },
  {
    text: "Name",
    value: "firstName",
    sortable: true,
    width: "250px",
  },
  {
    text: "Email",
    value: "email",
    sortable: true,
  },
  {
    text: "Phone",
    value: "phone",
    width: "100px",
    sortable: true,
  },
  {
    text: "Role",
    value: "contactRole",
    width: "150px",
    sortable: true,
  },
  {
    text: "Address",
    value: "addressLine1",
    sortable: false,
    width: "200px",
  },
  {
    text: "State",
    value: "contactState",
    sortable: true,
  },
  {
    text: "Added By",
    value: "createdBy",
    sortable: false,
  },
  {
    text: "Updated By",
    value: "updatedBy",
    sortable: false,
  },
];
