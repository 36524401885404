<template>
  <v-container class="pa-6" v-if="selected != null">
    <v-row class="mt-0 mb-2">
      <v-col cols="12" md="6" sm="12" class="py-0">
        <v-text-field-alt
          label="Website Link"
          placeholder="Website Link"
          clearable
          :rules="[allRules.url]"
          v-model="selected.domain"
          :readonly="readonly"
          :append-outer-icon="
            selected.domain != null && allRules.isUrl(selected.domain) == true
              ? 'fal fa-external-link fs-18px'
              : ''
          "
          class="shrink"
          @click:append-outer="openURL()"
        >
        </v-text-field-alt>
      </v-col>
      <v-col cols="12" md="6" sm="12" class="py-0">
        <v-select-alt
          label="Industry"
          placeholder="Industry"
          v-model="selected.industryType"
          :items="industryTypes"
          :readonly="readonly"
          itemValue="value"
          itemText="text"
          small
          dense
        ></v-select-alt>
      </v-col>
      <v-col cols="12" md="6" sm="12" class="pt-0">
        <label class="input-label mb-1">Phone Number</label>
        <maz-phone-number-input
          clearable
          :class="{ 'hide-clear-btn': readonly }"
          id="contactPhone"
          placeholder="Phone Number"
          color="primary"
          v-model="selected.phone"
          :disabled="readonly"
          :default-phoner-number="selected.phone"
          default-country-code="US"
          size="sm"
        >
        </maz-phone-number-input>
      </v-col>
    </v-row>
    <address-detail :readonly="readonly" v-model="selected"></address-detail>
  </v-container>
</template>

<script>
import Enums from "../../../../plugins/enums";
import MazPhoneNumberInput from "maz-ui/lib/maz-phone-number-input";
import "maz-ui/lib/css/base.css";
import "maz-ui/lib/css/maz-phone-number-input.css";
import AddressDetail from "@Shared/components/AddressDetail.vue";

export default {
  name: "company-details",
  components: { MazPhoneNumberInput, AddressDetail },
  data() {
    return {
      enums: Enums,
      industryTypes: this.$options.filters.EnumToList(Enums.INDUSTRY_TYPE, true),
      selected: null,
    };
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    this.selected = this.value;
  },
  methods: {
    openURL() {
      window.open(this.selected.domain);
    },
  },
  watch: {
    value: {
      handler() {
        if (!this.isEqual(this.selected, this.value)) {
          this.selected = this.value;
        }
      },
      deep: true,
    },
    selected: {
      handler() {
        if (!this.isEqual(this.selected, this.value)) {
          this.$emit("input", this.selected);
        }
      },
      deep: true,
    },
  },
};
</script>
