<template>
  <div>
    <v-menu offset-y right>
      <template v-slot:activator="{ attrs: attrsMenu, on: onMenu }">
        <v-tooltip top z-index="999" nudge-top="-4px">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-btn v-bind="attrsMenu" v-on="onMenu" small height="32" width="32" color="white">
                <i class="fad fa-arrow-down-to-square" style="font-size: 16px"></i>
              </v-btn>
            </div>
          </template>
          <span>Bulk Import Company Contacts</span>
        </v-tooltip>
      </template>
      <v-list dense>
        <v-list-item link style="min-height: 32px" @click="exportTemplate">
          <v-list-item-icon class="mr-2 justify-center">
            <v-icon small color="secondary">fal fa-file-arrow-down</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title style="font-size: 12px !important" class="font-weight-medium">
              Export Empty Template
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link style="min-height: 32px" @click="importTemplate">
          <v-list-item-icon class="mr-2 justify-center">
            <v-icon small color="secondary">fas fa-file-arrow-up</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title style="font-size: 12px !important" class="font-weight-medium">
              Import Filled Template
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Exported empty template  -->
    <v-dialog
      v-model="exportEmptyTemplate.dialog"
      max-width="450px"
      style="z-index: 99999999"
      @click:outside="exportEmptyTemplate.loading = true"
    >
      <v-fade-transition mode="out-in">
        <v-card v-if="exportEmptyTemplate.loading" :key="'export-loading-card'">
          <v-card-text class="py-3">
            <h3 class="mb-2">Generating template...</h3>
            <v-progress-linear indeterminate color="info" class="mb-1" rounded></v-progress-linear>
          </v-card-text>
        </v-card>
        <v-card v-else :key="'export-result-card'">
          <v-card-title class="font-weight-bold" small>
            <i class="fad fa-file-arrow-down mr-2"></i>Template is ready!
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container class="py-0">
              <div class="text-center py-7 mt-3 d-flex justify-center align-center flex-column">
                <div class="report-file-icon" :class="exportEmptyTemplate.reportFileExt">
                  <i
                    class="fad fa-file-excel"
                    v-if="exportEmptyTemplate.reportFileExt == 'xlsx'"
                  ></i>
                </div>
                <h3 class="report-result mt-2">Report Generated</h3>
                <h3 class="report-name mt-2 text--secondary">
                  {{ exportEmptyTemplate.reportFileName }}
                </h3>
                <v-btn
                  text
                  color="info"
                  class="report-download-link mt-2"
                  :href="exportEmptyTemplate.reportUrl"
                  :download="exportEmptyTemplate.reportFileName"
                >
                  <i class="fad fa-arrow-alt-to-bottom mr-2"></i>Download
                </v-btn>
              </div>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="
                exportEmptyTemplate.dialog = false;
                exportEmptyTemplate.loading = true;
              "
            >
              <i class="fal fa-chevron-left mr-2"></i> Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-fade-transition>
    </v-dialog>

    <!-- Import filled drawing documents templat -->
    <v-dialog
      v-model="importFilledTemplate.dialog"
      max-width="500px"
      style="z-index: 99999999"
      persistent
    >
      <v-fade-transition mode="out-in">
        <v-card>
          <v-card-title class="font-weight-bold" small>
            <i class="fad fa-file-arrow-up mr-2"></i>
            Import Filled Template Bulk File
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container class="pt-5 pb-0">
              <v-file-input
                v-model="importFilledTemplate.file"
                :rules="importFilledTemplate.rules"
                accept=".xlsx"
                label="Company Contacts Bulk Import Template File"
                outlined
                dense
                show-size
                counter
                truncate-length="46"
                :loading="importFilledTemplate.loading"
                :disabled="importFilledTemplate.loading"
              >
                <template v-slot:selection="{ text }">
                  <v-chip color="info" dark small style="max-height: 18px; margin-top: 8px">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="closeImportTemplate()" :disabled="importFilledTemplate.loading">
              <i class="fal fa-times mr-2"></i> Cancel
            </v-btn>
            <v-btn
              color="info"
              @click="startImportingTemplate"
              :loading="importFilledTemplate.loading"
              :disabled="importFilledTemplate.loading || !templateValid"
            >
              <i class="fad fa-up-from-bracket mr-2"></i> Import
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-fade-transition>
    </v-dialog>
  </div>
</template>

<script>
import axios from "@/plugins/axios.js";
import perms from "../../../../plugins/permissions";
import companyContactsService from "../services/companyContacts-service";

export default {
  data() {
    return {
      perms: perms,
      exportEmptyTemplate: {
        dialog: false,
        loading: false,
        reportUrl: "",
        reportFileName: "",
        reportFileExt: "",
      },
      importFilledTemplate: {
        dialog: false,
        loading: false,
        files: [],
        cancellation: null,
        rules: [
          (file) =>
            !file ||
            file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            "Only .xlsx excel files are acceptable!",
        ],
      },
    };
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  mounted() {},
  computed: {
    templateValid() {
      if (
        this.importFilledTemplate.file != null &&
        this.importFilledTemplate.file.type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
        return true;
      return false;
    },
    templateType() {
      if (this.importFilledTemplate.file != null) return this.importFilledTemplate.file.type;
      return null;
    },
  },
  methods: {
    exportTemplate() {
      this.exportEmptyTemplate.loading = true;
      setTimeout(() => {
        this.startExportingEmptyTemplate();
      }, 50);
    },
    startExportingEmptyTemplate() {
      this.exportEmptyTemplate.dialog = true;
      companyContactsService
        .exportTemplate(this.id)
        .then((resp) => {
          this.exportEmptyTemplate.dialog = true;
          this.exportEmptyTemplate.loading = false;
          let url = URL.createObjectURL(
            new Blob([resp.data], {
              type: resp.data.type,
            })
          );
          this.exportEmptyTemplate.reportUrl = url;
          this.exportEmptyTemplate.reportFileName = resp.headers["x-file-name"];
          this.exportEmptyTemplate.reportFileExt = resp.headers["x-file-name"].split(".").pop();
        })
        .catch((err) => {
          this.exportEmptyTemplate.dialog = false;
          this.exportEmptyTemplate.loading = true;
          this.$handleError(err);
        });
    },
    importTemplate() {
      this.importFilledTemplate.dialog = true;
    },
    startImportingTemplate() {
      this.importFilledTemplate.loading = true;
      var formData = new FormData();
      formData.append(
        "formFile",
        this.importFilledTemplate.file,
        this.importFilledTemplate.file.name
      );
      const _cancelToken = axios.CancelToken;
      this.importFilledTemplate.cancellation = {
        cancelToken: _cancelToken,
        source: _cancelToken.source(),
      };
      formData.append("cancellation", this.importFilledTemplate.cancellation.source.token);
      companyContactsService
        .import(this.id, formData, {
          cancelToken: this.importFilledTemplate.cancellation.source.token,
        })
        .then((response) => {
          this.$dialog.notify.success("Contacts are imported successfully!", {
            position: "top-right",
            timeout: 10 * 1000,
          });
          this.closeImportTemplate();
          this.$emit("imported");
        })
        .catch((error) => {
          this.$log("Uploading Template Error:", error);
          this.closeImportTemplate();

          if (axios.isCancel(error)) {
            this.$log("Importing template is cancelled by user!", error.message);
            this.$dialog.notify.error("Importing template is cancelled by user!", {
              position: "top-right",
              timeout: 10 * 1000,
            });
          } else {
            //handle error
            this.$handleError(error);
          }
        });
    },
    closeImportTemplate() {
      this.importFilledTemplate.dialog = false;
      this.importFilledTemplate.loading = false;
      this.importFilledTemplate.file = [];
    },
  },
  watch: {},
  components: {},
};
</script>

<style lang="scss">
.contacts-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}
</style>
