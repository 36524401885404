<template>
  <v-row class="mt-3" v-if="selected != null">
    <v-col cols="12" md="4" sm="12" class="py-0" v-if="hasAddressLine">
      <v-text-field-alt
        :rules="[allRules.noWhiteSpaces]"
        label="Address"
        id="selectedAddress"
        placeholder="Address"
        v-model="selected.addressLine1"
        :readonly="readonly"
      >
      </v-text-field-alt>
    </v-col>
    <v-col cols="12" md="4" sm="12" class="py-0" v-else>
      <v-text-field-alt
        :rules="[allRules.noWhiteSpaces]"
        label="Address"
        id="selectedAddress"
        placeholder="Address"
        v-model="selected.address"
        :readonly="readonly"
      >
      </v-text-field-alt>
    </v-col>
    <v-col cols="12" md="4" sm="12" class="py-0">
      <!-- <v-select-alt
        label="Country"
        v-model="selected.country"
        id="selectedCountry"
        placeholder="Country"
        :items="DNA_COUNTRIES"
        :loading="isPostalCodeLoading"
        dense
        filled
        item-text="text"
        item-value="value"
        :readonly="readonly"
      >
      </v-select-alt> -->
      <v-autocomplete-alt
        auto-select-first
        label="Country"
        v-model="selected.country"
        id="selectedCountry2"
        placeholder="Country"
        :items="WORLD_COUNTRIES_LIST"
        :loading="isPostalCodeLoading"
        :disabled="isPostalCodeLoading"
        dense
        filled
        item-text="name"
        item-value="code"
        :readonly="readonly"
      >
      </v-autocomplete-alt>
    </v-col>
    <v-col cols="12" md="4" sm="12" class="py-0">
      <v-text-field-alt
        :label="
          autoZipCodeLookup
            ? `Postal/Zip Code <i class='fad fa-bolt-auto ml-2'></i>`
            : `Postal/Zip Code`
        "
        id="selectedPostalCode"
        placeholder="Postal/Zip Code"
        v-model="selected.postalCode"
        :error-messages="zipCodeErrors"
        @blur="getPostalCode"
        :loading="isPostalCodeLoading"
        :disabled="isPostalCodeLoading"
        @update:error="checkError"
        @input="checkZipCodeValidity"
        :readonly="readonly"
      >
      </v-text-field-alt>
    </v-col>
    <v-col cols="12" md="4" sm="12" class="py-0">
      <v-text-field-alt
        :rules="[allRules.noWhiteSpaces]"
        label="City"
        id="selectedCity"
        placeholder="City"
        v-model="selected.city"
        :loading="isPostalCodeLoading"
        :disabled="isPostalCodeLoading"
        :readonly="readonly"
      >
      </v-text-field-alt>
    </v-col>
    <v-col cols="12" md="4" sm="12" class="py-0">
      <v-text-field-alt
        :rules="[allRules.noWhiteSpaces]"
        label="County"
        id="selectedCounty"
        placeholder="County"
        v-model="selected.county"
        :loading="isPostalCodeLoading"
        :disabled="isPostalCodeLoading"
        :readonly="readonly"
      >
      </v-text-field-alt>
    </v-col>
    <v-col cols="12" md="4" sm="12" class="py-0">
      <v-autocomplete-alt
        auto-select-first
        label="State/Province"
        v-model="selected.state"
        id="selectedState"
        placeholder="State/Province"
        :items="RegionsList"
        :loading="isPostalCodeLoading"
        :disabled="isPostalCodeLoading"
        dense
        filled
        item-text="text"
        item-value="value"
        :readonly="readonly"
      >
      </v-autocomplete-alt>
    </v-col>
  </v-row>
</template>

<script>
import Enums from "../../../plugins/enums";
import FormRules from "../../../plugins/form-rules";

export default {
  data() {
    return {
      selected: {},
      zipCodeErrors: [],
      isPostalCodeLoading: false,
      isPostalCodeValid: false,
      US_STATES: this.$options.filters.EnumToList(Enums.US_STATES, true),
      MX_STATES: this.$options.filters.EnumToList(Enums.MX_STATES, true),
      CA_PROVINCES: this.$options.filters.EnumToList(Enums.CA_PROVINCES, true),
      PL_PROVINCES: this.$options.filters.EnumToList(Enums.PL_PROVINCES, true),
      DNA_COUNTRIES: this.$options.filters.EnumToList(
        Enums.DNA_COUNTRIES,
        true
      ),
      WORLD_COUNTRIES_LIST: Enums.WORLD_COUNTRIES_LIST
    };
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: null
    },
    hasAddressLine: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.selected = this.cloneDeep(this.value);
  },
  computed: {
    RegionsList() {
      switch (this.selected.country) {
        case Enums.DNA_COUNTRIES.US.value:
          return this.US_STATES;
          break;
        case Enums.DNA_COUNTRIES.MX.value:
          return this.MX_STATES;
          break;
        case Enums.DNA_COUNTRIES.CA.value:
          return this.CA_PROVINCES;
          break;
        case Enums.DNA_COUNTRIES.PL.value:
          return this.PL_PROVINCES;
          break;
      }
      return [];
    },
    autoZipCodeLookup() {
      switch (this.selected.country) {
        case Enums.DNA_COUNTRIES.US.value:
        case Enums.DNA_COUNTRIES.MX.value:
        case Enums.DNA_COUNTRIES.CA.value:
        case Enums.DNA_COUNTRIES.PL.value:
          return true;
          break;
      }
      return false;
    }
  },
  methods: {
    checkZipCodeValidity() {
      var postalCode = this.selected.postalCode;
      var postalCodeHasValue = postalCode != null && postalCode != "";
      var noCountryIsSelected =
        this.selected == null ||
        this.selected.country == null ||
        this.selected.country == "";
      this.$log("checkZipCodeValidity", postalCode);
      if (noCountryIsSelected && postalCodeHasValue) {
        this.zipCodeErrors = ["Please Select a Country!"];
        return;
      }
      var result = true;
      switch (this.selected.country) {
        case Enums.DNA_COUNTRIES.US.value:
          result = FormRules.usPostalCode(postalCode);
          break;
        case Enums.DNA_COUNTRIES.CA.value:
          result = FormRules.canadaPostalCode(postalCode);
          break;
        case Enums.DNA_COUNTRIES.MX.value:
          result = FormRules.mexicoPostalCode(postalCode);
          break;
        case Enums.DNA_COUNTRIES.PL.value:
          result = FormRules.polandPostalCode(postalCode);
          break;
      }
      if (result != true) this.zipCodeErrors = [result];
      else this.zipCodeErrors = [];
    },
    checkError(hasError) {
      this.isPostalCodeValid = !hasError;
    },
    getPostalCode(code) {
      if (this.readonly) return;
      setTimeout(() => {
        var postalCode = this.selected.postalCode;
        var postalCodeHasValue = postalCode != null && postalCode != "";
        var noCountryIsSelected =
          this.selected == null ||
          this.selected.country == null ||
          this.selected.country == "";

        if (
          noCountryIsSelected ||
          this.zipCodeErrors.length > 0 ||
          !postalCodeHasValue ||
          !this.autoZipCodeLookup
        )
          return;

        this.isPostalCodeLoading = true;
        // Call Ziptastic for information
        fetch(
          `https://secure.geonames.org/postalCodeSearchJSON?country=${this.selected.country}&postalcode=${postalCode}&username=khairyabdelzaher`
        )
          .then(res => res.json())
          .then(res => {
            if (res.postalCodes.length > 0) {
              let locationCountryCode = res.postalCodes[0].countryCode;
              let locationCity = "";
              let locationCounty = "";
              let locationState = "";

              switch (this.selected.country) {
                case Enums.DNA_COUNTRIES.US.value:
                  locationCity = res.postalCodes[0].placeName;
                  locationState = res.postalCodes[0].adminCode1;
                  locationCounty = res.postalCodes[0].adminName2;
                  break;
                case Enums.DNA_COUNTRIES.CA.value:
                  locationCity = res.postalCodes[0].placeName;
                  locationState = res.postalCodes[0].adminName1;
                  break;
                case Enums.DNA_COUNTRIES.MX.value:
                  locationCity = res.postalCodes[0].placeName;
                  locationState = res.postalCodes[0].adminName1;
                  locationCounty = res.postalCodes[0].adminName2;
                  break;
                case Enums.DNA_COUNTRIES.PL.value:
                  locationCity = res.postalCodes[0].placeName;
                  locationState = res.postalCodes[0].adminName1;
                  locationCounty =
                    res.postalCodes[0].adminName2 +
                    ", " +
                    res.postalCodes[0].adminName3;
                  break;
              }
              /////////////////
              this.$log("res.postalCodes[0]", res.postalCodes[0]);
              this.selected.city = locationCity;
              this.selected.state = locationState;
              this.selected.county = locationCounty;
              this.selected.country = locationCountryCode;
            } else {
              this.$dialog.notify.warning(
                `No matching address for this zip code: <u>${postalCode}</u>`,
                {
                  position: "top-right",
                  timeout: 3000
                }
              );
              return;
              this.selected.city = "";
              this.selected.state = "";
              this.selected.county = "";
              this.selected.country = "";
            }
          })
          .catch(err => {
            this.$log(err);
            this.$handleError(err);
          })
          .finally(() => (this.isPostalCodeLoading = false));
      }, 50);
    }
  },
  watch: {
    value: {
      handler() {
        if (this.isEqual(this.value, this.selected)) return;
        this.selected = this.cloneDeep(this.value);
      },
      deep: true
    },
    selected: {
      handler() {
        if (this.isEqual(this.value, this.selected)) return;
        this.$emit("input", this.selected);
      },
      deep: true
    },
    "selected.country": {
      handler() {
        this.checkZipCodeValidity();
      },
      deep: true
    }
  }
};
</script>

<style></style>
